class MesssageUtil {
  /**
   * 指定のIDのメッセージを返します。
   * @param {String} messageId
   * @param {Array<String>} args
   * @returns {String}
   */
  getMessage(messageId, args) {
    const messageObj = this.getMessageObj(messageId);
    if (messageObj) {
      let message = messageObj.message;
      if (args) {
        args.forEach((arg, idx) => {
          message = message.replace("{" + idx + "}", arg);
        });
      }
      return message;
    }
    return null;
  }

  /**
   * 指定のIDのメッセージオブジェクトを返します。
   * @param {String} messageId
   * @returns {Json}
   */
  getMessageObj(messageId) {
    const searchRule = function (obj) {
      if (obj.id == messageId) {
        return true;
      }
      return false;
    };
    return this.messageList.find(searchRule);
  }

  messageList = [
    {
      id: "P-COM-001_001_E",
      level: "error",
      message: "認証できません。ユーザーIDが登録されていることを確認してください。",
    },
    { id: "P-COM-001_002_E", level: "error", message: "{0}、{1}は必須です。" },
    {
      id: "P-COM-001_003_W",
      level: "success",
      message: "セッションタイムアウトしています。再度ログインしてください。",
    },
    {
      id: "P-MNG-001_001_E",
      level: "error",
      message: "営業所が選択されていません。選択してください。",
    },
    {
      id: "P-MNG-001_002_E",
      level: "error",
      message: "搬入日付が選択されていません。選択してください。",
    },
    {
      id: "P-MNG-003_001_E",
      level: "error",
      message: "開始の日付が選択されていません。選択してください。",
    },
    {
      id: "P-MNG-003_002_E",
      level: "error",
      message: "終了の日付が選択されていません。選択してください。",
    },
    {
      id: "P-MNG-003_003_E",
      level: "error",
      message: "営業所が選択されていません。選択してください。",
    },
    {
      id: "P-MNG-003_004_E",
      level: "error",
      message: "開始の日付より終了の日付の方が後になるよう、選択してください。",
    },
    {
      id: "P-MNG-002_001_C",
      level: "success",
      message: "ステータスを「積付完了」に更新いたします。よろしいでしょうか？",
    },
    { id: "P-MNG-002_002_C", level: "success", message: "更新に成功しました。" },
    {
      id: "P-MNG-002_003_C",
      level: "success",
      message: "ステータスを「積込完了」に更新いたします。よろしいでしょうか？",
    },
    {
      id: "P-MNG-002_004_C",
      level: "success",
      message: "期限を延長いたします。よろしいでしょうか？",
    },
    {
      id: "P-MNG-001_100_E",
      level: "error",
      message: "最終更新日時の日時形式が誤っています。",
    },
    { id: "P-MNG-001_101_E", level: "error", message: "拠点区分は1桁です。" },
    { id: "P-MNG-001_102_E", level: "error", message: "検索区分は必須です。" },
    { id: "P-MNG-001_103_E", level: "error", message: "検索区分は2桁です。" },
    { id: "P-MNG-001_104_E", level: "error", message: "検索値01は必須です。" },
    { id: "P-MNG-001_105_E", level: "error", message: "検索値02は必須です。" },
    { id: "P-MNG-001_106_E", level: "error", message: "取引先区分は1桁です。" },
    { id: "P-MNG-001_107_E", level: "error", message: "処理区分は必須です。" },
    { id: "P-MNG-001_108_E", level: "error", message: "処理区分は2桁です。" },
    { id: "P-MNG-001_109_E", level: "error", message: "営業所コードは7桁です。" },
    { id: "P-MNG-001_110_E", level: "error", message: "営業所コードは必須です。" },
    { id: "P-MNG-001_111_E", level: "error", message: "搬入日付は必須です。" },
    { id: "P-MNG-001_112_E", level: "error", message: "搬入日付の日付形式が誤っています。" },
    { id: "P-MNG-001_113_E", level: "error", message: "検索区分は必須です。" },
    { id: "P-MNG-001_114_E", level: "error", message: "検索区分は1桁です。" },
    { id: "P-MNG-001_115_E", level: "error", message: "取引先コードは7桁です。" },
    { id: "P-MNG-001_116_E", level: "error", message: "取引先コードは必須です。" },
    { id: "P-MNG-001_117_E", level: "error", message: "搬入場所コードは10桁です。" },
    { id: "P-MNG-001_118_E", level: "error", message: "搬入場所コードは必須です。" },
    { id: "P-MNG-002_100_E", level: "error", message: "処理区分は必須です。" },
    { id: "P-MNG-002_101_E", level: "error", message: "処理区分は2桁です。" },
    { id: "P-MNG-002_102_E", level: "error", message: "営業所コードは必須です。" },
    { id: "P-MNG-002_103_E", level: "error", message: "営業所コードは7桁です。" },
    { id: "P-MNG-002_104_E", level: "error", message: "搬入日付は必須です。" },
    { id: "P-MNG-002_105_E", level: "error", message: "搬入日付の日付形式が誤っています。" },
    { id: "P-MNG-002_106_E", level: "error", message: "搬入時間帯は必須です。" },
    { id: "P-MNG-002_107_E", level: "error", message: "搬入時間帯は2桁です。" },
    { id: "P-MNG-002_108_E", level: "error", message: "取引先コードは必須です。" },
    { id: "P-MNG-002_109_E", level: "error", message: "取引先コードは7桁です。" },
    { id: "P-MNG-002_110_E", level: "error", message: "搬入場所コードは必須です。" },
    { id: "P-MNG-002_111_E", level: "error", message: "搬入場所コードは10桁です。" },
    { id: "P-MNG-002_112_E", level: "error", message: "納品書Noリストは必須です。" },
    { id: "P-MNG-002_113_E", level: "error", message: "納品書Noは必須です。" },
    { id: "P-MNG-002_114_E", level: "error", message: "納品書Noは13桁です。" },
    { id: "P-MNG-002_115_E", level: "error", message: "納品書SEQは必須です。" },
    { id: "P-MNG-002_116_E", level: "error", message: "納品書SEQは10桁です。" },
    { id: "P-MNG-002_117_E", level: "error", message: "処理区分は必須です。" },
    { id: "P-MNG-002_118_E", level: "error", message: "処理区分は1桁です。" },
    { id: "P-MNG-002_119_E", level: "error", message: "期限時間は数値です。" },
    { id: "P-MNG-002_120_E", level: "error", message: "最終更新日時は必須です。" },
    {
      id: "P-MNG-002_121_E",
      level: "error",
      message: "最終更新日時の日時形式が誤っています。",
    },
    { id: "P-MNG-003_100_E", level: "error", message: "搬入日付Fromは必須です。" },
    {
      id: "P-MNG-003_101_E",
      level: "error",
      message: "搬入日付Fromの日付形式が誤っています。",
    },
    { id: "P-MNG-003_102_E", level: "error", message: "搬入日付Toは必須です。" },
    {
      id: "P-MNG-003_103_E",
      level: "error",
      message: "搬入日付Toの日付形式が誤っています。",
    },
    { id: "P-MNG-003_104_E", level: "error", message: "営業所コードは必須です。" },
    { id: "P-MNG-003_105_E", level: "error", message: "営業所コードは7桁です。" },
    { id: "P-MNG-003_106_E", level: "error", message: "製作所コードは7桁です。" },
    { id: "P-MNG-003_107_E", level: "error", message: "取引先コードは7桁です。" },
    { id: "P-MNG-003_108_E", level: "error", message: "搬入場所コードは10桁以内です。" },
    { id: "P-MNG-003_109_E", level: "error", message: "拠点区分は1桁です。" },
    { id: "P-MNG-003_110_E", level: "error", message: "取引先区分は1桁です。" },
    { id: "A-PCK-001_100_E", level: "error", message: "すでにモジュールヘッダに登録があります。" },
    {
      id: "A-PCK-001_101_E",
      level: "error",
      message: "モジュールヘッダ件数が不正のため処理できません。",
    },
    {
      id: "A-PCK-001_102_E",
      level: "error",
      message: "モジュールステータスが積付以外のため処理できません。",
    },
    {
      id: "A-PCK-001_103_E",
      level: "error",
      message: "すでにモジュール積込詳細履歴があるため処理できません。",
    },
    {
      id: "A-PCK-001_104_E",
      level: "error",
      message: "混載区分が0:なしまたは1:混載以外のため処理できません。",
    },
    {
      id: "A-PCK-001_105_E",
      level: "error",
      message: "モジュールステータスが積込以外のため処理できません。",
    },
    {
      id: "A-PCK-001_106_E",
      level: "error",
      message: "デポ出荷用のモジュールであるため、荷崩しできません。",
    },
    { id: "A-PCK-001_107_E", level: "error", message: "搬入指示データがありません。" },
    {
      id: "A-PCK-001_108_E",
      level: "error",
      message: "他のユーザーが更新している搬入指示データがあるため、更新できませんでした。",
    },
    {
      id: "A-RCV-001_100_E",
      level: "error",
      message: "他のユーザーが更新している搬入指示データがあるため、更新できませんでした。",
    },
    { id: "A-PCK-002_100_E", level: "error", message: "すでにモジュールヘッダに登録があります。" },
    {
      id: "A-PCK-003_100_E",
      level: "error",
      message: "該当のモジュールIDのデータが存在しないため、モジュールを操作できません。",
    },
    {
      id: "A-PCK-003_101_E",
      level: "error",
      message: "モジュールステータスが積付でないため、モジュールを受け入れできません。",
    },
    {
      id: "A-PCK-003_102_E",
      level: "error",
      message: "他のユーザーが更新している搬入指示データがあるため、更新できませんでした。",
    },
    { id: "A-COM-004_101_E", level: "error", message: "搬入日付は必須です。" },
    { id: "A-PCK-002_101_E", level: "error", message: "搬入指示データがありません。" },
    { id: "A-999-999_999_E", level: "error", message: "システムエラーが発生しました。" },
    { id: "A-999-999_002_E", level: "error", message: "2重送信チェックエラーが発生しました。" },
    { id: "A-PCK-002_102_E", level: "error", message: "モジュール履歴が登録できませんでした。" },
    {
      id: "P-999-999_999_E",
      level: "error",
      message: "APIを実行した際にシステムエラーが発生しました。",
    },
    { id: "P_MNG_004_001_E", level: "error", message: "排他制御エラー" },
    { id: "P_MNG_003_001_E", level: "error", message: "拠点は必須です" },
    { id: "P_MNG_003_002_E", level: "error", message: "計画コードは必須です" },
    { id: "P_MNG_003_003_E", level: "error", message: "搬入先アドレスは必須です" },
    { id: "P_MNG_003_004_E", level: "error", message: "グループは必須です" },
    { id: "P_MNG_003_005_E", level: "error", message: "グループ名は必須です" },
  ];
}

export const messsageUtil = new MesssageUtil();
